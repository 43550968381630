import React from "react";

const HomeFooter = () => {
    return (
        <div>
            <div className="Home_homeContentTextEnd__SGVob">
                THANK YOU FOR CHOOSING ATHENE NETWORK!
            </div>
            <div className="Home_homeFooter__4vo5d">
                2024 Athene Group LTD. | All rights reserved.
            </div>
        </div>
    );
};

export default HomeFooter;
