import React, { useState } from "react";

const HomeContentPurchase = () => {
    const [showHowTo, setShowHowTo] = useState(false);
    return (
        <div
            class="Home_homeContentPurchase__YMuB7"
            style={{ userSelect: "none" }}
        >
            <div class="Home_homeContentPurchaseContainer___zVPr">
                <div>
                    <div
                        className="Home_homeContentPurchaseMain__qx_rk"
                        onClick={() => setShowHowTo(!showHowTo)}
                    >
                        <div className="Home_homeContentPurchaseTitle__Dwbpe">
                            How to purchase?
                        </div>
                        <img
                            alt=""
                            loading="lazy"
                            width="24"
                            height="24"
                            decoding="async"
                            data-nimg="1"
                            className="Home_homeContentChooieImage__HSPxa"
                            src="assets/left.a8efa7d1.svg"
                            style={{
                                color: "transparent",
                            }}
                        />
                    </div>
                </div>
                <div
                    class="Home_homeContentPurchaseSubTitle__tUMNt"
                    style={{ display: `${showHowTo ? "block" : "none"}` }}
                >
                    <div class="Home_homeContentPurchaseSubTitleItem__7veKK">
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                1. Connect your wallet by clicking the button
                                'Connect Wallet'.
                            </div>
                        </li>
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                2. Switch to the Binance Smart Chain (BEP20)
                                network.
                            </div>
                        </li>
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                3. Choose the package you want to purchase.
                            </div>
                        </li>
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                4. Enter your Athene account (your email).
                            </div>
                        </li>
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                5. Click the 'BUY' button and confirm the
                                transaction.
                            </div>
                        </li>
                        <li class="Home_homeContentPurchaseSubTitleMain__EQfsK">
                            <div>
                                6. The system will automatically process
                                transactions and update your account's package.
                            </div>
                        </li>
                    </div>
                    <div class="Home_homeContentPurchaseVideo__ds3Sp">
                        <iframe
                            class="Home_homeContentPurchaseVideoitem__Hevf1"
                            height="400"
                            src="https://www.youtube.com/embed/I0HYNE2T9d8"
                            title="Step-by-step instructions for purchasing the Premium Silver package"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeContentPurchase;
