import React from "react";

const SideNav = () => {
    return (
        <div className="Sidebar_sidebar__oTTpu ">
            <div className="Sidebar_sidebarContainer__DxHFn">
                <div className="Sidebar_sidebar_btn__cOnIo">
                    <a href="/">
                        <img
                            alt=""
                            loading="lazy"
                            width="136"
                            height="17"
                            decoding="async"
                            data-nimg="1"
                            src="assets/logopc.0d0e9d38.svg"
                            style={{
                                color: "transparent",
                            }}
                        />
                    </a>
                    <div>
                        <img
                            alt=""
                            loading="lazy"
                            width="24"
                            height="24"
                            decoding="async"
                            data-nimg="1"
                            srcset="../../public/assets/Close.8d5aaf9f.png 1x, ../../public/assets/Close.8d5aaf9f.png 2x"
                            src="../../public/assets/Close.8d5aaf9f.png"
                            style={{
                                color: "transparent",
                            }}
                        />
                    </div>
                </div>
                <ul className="Sidebar_sidebarUl__KMePg">
                    <li className="Sidebar_sidebarli__bTR8P">
                        <a className="Sidebar_sidebara__BI2VQ" href="/">
                            Home
                        </a>
                    </li>
                    <li className="Sidebar_sidebarli__bTR8P">
                        <a className="Sidebar_sidebara__BI2VQ" href="/history/">
                            History
                        </a>
                    </li>
                    <li className="Sidebar_sidebarli__bTR8P">
                        <div className="Sidebar_sidebara__BI2VQ">Language</div>
                        <div className="Sidebar_sidebaraLang__pMSpX">
                            <div className="MuiSelect-root MuiSelect-variantSolid MuiSelect-colorNeutral MuiSelect-sizeMd CustomSelect_selectLangue__De7sO css-5ql82x">
                                <button
                                    role="combobox"
                                    aria-expanded="false"
                                    aria-controls=":r2:"
                                    className="MuiSelect-button css-1qmzz5g"
                                    type="button"
                                >
                                    <span className="MuiListItemDecorator-root css-1une5eo">
                                        <img
                                            alt=""
                                            loading="lazy"
                                            width="24"
                                            height="24"
                                            decoding="async"
                                            data-nimg="1"
                                            src="assets/united-kingdom.d5e49076.svg"
                                            style={{
                                                color: "transparent",
                                            }}
                                        />
                                    </span>
                                    English
                                </button>
                                <span className="MuiSelect-indicator css-1tnykim">
                                    <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="KeyboardArrowDownIcon"
                                    >
                                        <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>
                                    </svg>
                                </span>
                                <input
                                    tabindex="-1"
                                    aria-hidden="true"
                                    value="en"
                                    style={{
                                        border: "0px",
                                        clip: "rect(0px, 0px, 0px, 0px)",
                                        height: "1px",
                                        margin: "-1px",
                                        overflow: "hidden",
                                        padding: "0px",
                                        position: "absolute",
                                        whiteSpace: "nowrap",
                                        width: "1px",
                                    }}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SideNav;
