import React from "react";

const Header = () => {
    return (
        <div className="Home_header__ZUWxe">
            <div className="Home_navbar__MyWFx">
                <div className="Home_logo__ZEOng">
                    <a href="/">
                        <img
                            alt=""
                            loading="lazy"
                            width="136"
                            height="17"
                            decoding="async"
                            data-nimg="1"
                            src="assets/logopc.0d0e9d38.svg"
                            style={{
                                color: "transparent",
                            }}
                        />
                    </a>
                </div>
                <div className="Home_logomb__9FMuz">
                    <div className="Home_logombTwo__IEMzM">
                        <img
                            alt=""
                            loading="lazy"
                            width="24"
                            height="24"
                            decoding="async"
                            data-nimg="1"
                            className="Home_logombIcon__wCAJA"
                            src="assets/main.4620ce03.svg"
                            style={{
                                color: "transparent",
                            }}
                        />
                        <a href="/">
                            <img
                                alt=""
                                loading="lazy"
                                width="91"
                                height="12"
                                decoding="async"
                                data-nimg="1"
                                src="assets/logomb.c87795bb.svg"
                                style={{
                                    color: "transparent",
                                }}
                            />
                        </a>
                    </div>
                </div>
                <nav className="Home_nav__ZMqH2">
                    <a href="/">
                        <div className="Home_activeTab__P7pXh">Home</div>
                    </a>
                    <a href="/">
                        <div className="Home_tab__4XWq1">History</div>
                    </a>
                </nav>
            </div>
            <div className="Home_actionBtn__DBP4e">
                <div className="Home_actionBtnConnet__TXGJy">
                    <div className="Home_buttons__L_amR">
                        <div className="Home_highlight__TZ0SE ">
                            <w3m-network-button></w3m-network-button>
                        </div>
                        <div className="Home_highlight__TZ0SE ">
                            <w3m-button></w3m-button>
                        </div>
                    </div>
                </div>
                <div className="Home_actionBtnSelect__TpABK">
                    <div className="MuiSelect-root MuiSelect-variantSolid MuiSelect-colorNeutral MuiSelect-sizeMd CustomSelect_selectLangue__De7sO css-5ql82x">
                        <button
                            role="combobox"
                            aria-expanded="false"
                            aria-controls=":r0:"
                            className="MuiSelect-button css-1qmzz5g"
                            type="button"
                        >
                            <span className="MuiListItemDecorator-root css-1une5eo">
                                <img
                                    alt=""
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    decoding="async"
                                    data-nimg="1"
                                    src="assets/united-kingdom.d5e49076.svg"
                                    style={{
                                        color: "transparent",
                                    }}
                                />
                            </span>
                            English
                        </button>
                        <span className="MuiSelect-indicator css-1tnykim">
                            <svg
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="KeyboardArrowDownIcon"
                            >
                                <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>
                            </svg>
                        </span>
                        <input
                            tabindex="-1"
                            aria-hidden="true"
                            value="en"
                            style={{
                                border: "0px",
                                clip: "rect(0px, 0px, 0px, 0px)",
                                height: "1px",
                                margin: "-1px",
                                overflow: "hidden",
                                padding: "0px",
                                position: "absolute",
                                whiteSpace: "nowrap",
                                width: "1px",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
