import "./App.css";
import Header from "./components/Header";
import HomeContent from "./components/HomeContent";
import HomeFooter from "./components/HomeFooter";
import SideNav from "./components/SideNav";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    return (
        <div id="__next">
            <div>
                <div className="Home_premiunMain__GBZMZ">
                    <div className="Home_container__9OuOz">
                        <main>
                            <Header />
                            <HomeContent />
                            <HomeFooter />
                            <SideNav />
                        </main>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default App;
